export const roomCalculation = (allInventory) => {
    const filteredVisibleData = allInventory.filter(item => item.isVisible)
    const groupedData = {}

    const currentYear = new Date().getFullYear()
    const currentMonth = new Date().getMonth()
    const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate()

    filteredVisibleData.forEach(eachEntry => {
        const hostelName = eachEntry.roomType
        const date = new Date(eachEntry.date).getDate()

        if (!groupedData[hostelName]) {
            groupedData[hostelName] = {
                hostelName,
                availableRooms: Array(daysInMonth).fill(0),
                bookedRooms: Array(daysInMonth).fill(0),
                cost: Array(daysInMonth).fill(0)
            }
        }
        groupedData[hostelName].availableRooms[date - 1] = eachEntry.availableRooms
        groupedData[hostelName].bookedRooms[date - 1] = eachEntry.bookedRooms;
        groupedData[hostelName].cost[date - 1] = eachEntry.cost;
    })
    return Object.values(groupedData)
}

export const roomCalculation1 = (allInventory, fromDate, toDate) => {
    const filteredVisibleData = allInventory.filter(item => item.isVisible)
    const groupedData = {}

    const currentYear = new Date().getFullYear()
    const currentMonth = new Date().getMonth()
    const today = new Date().getDate()
    const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate()

    const adjustedFromDate = fromDate && fromDate >= today ? fromDate : today;
    const adjustedToDate = toDate || daysInMonth
    filteredVisibleData.forEach(eachEntry => {
        const hostelName = eachEntry.roomType
        const date = new Date(eachEntry.date)
        console.log (date.getMonth(), currentMonth)
        if (date.getFullYear() === currentYear && date.getMonth() === currentMonth) {
            if (!groupedData[hostelName]) {
                groupedData[hostelName] = {
                    hostelName,
                    availableRooms: Array(daysInMonth).fill(0),
                    bookedRooms: Array(daysInMonth).fill(0),
                    cost: Array(daysInMonth).fill(0)
                };
            }
            if (date.getDate() >= adjustedFromDate && date.getDate() <= adjustedToDate) {
                groupedData[hostelName].availableRooms[date.getDate()] = eachEntry.availableRooms;
                groupedData[hostelName].bookedRooms[date.getDate()] = eachEntry.bookedRooms;
                groupedData[hostelName].cost[date.getDate()] = eachEntry.cost;
            }
        }
    })
    return Object.values(groupedData)
}


export const convertRoomToData = (allInventory) => {
    const originalData = [];
    const hostelName = allInventory.hostelName;

    for (let i = 0; i < allInventory.availableRooms.length; i++) {
        const availableRooms = allInventory.availableRooms[i];
        const bookedRooms = allInventory.bookedRooms[i];
        const cost = allInventory.cost[i];
        if (availableRooms !== 0 || bookedRooms !== 0 || cost !== 0) {
            const date = new Date(new Date().getFullYear(), new Date().getMonth(), i + 2).toISOString().split('T')[0];
            originalData.push({
                id: `123-${hostelName}-${date}`,
                created_at: new Date(),
                roomType: hostelName,
                hostelId: "123",
                date: date,
                availableRooms: availableRooms,
                bookedRooms: bookedRooms,
                cost: cost,
                isVisible: true
            });
        }
    }
    return originalData;
}

export const convertExceltoData = (excelData) => {
    const currentDate = new Date();
    const currentMonth = currentDate.toISOString().slice(0, 7);
    const currentYear = currentDate.getFullYear()

    const hostelName = excelData[0]["Name"];
    console.log(hostelName)
    const availableRooms = excelData[1];
    const bookedRooms = excelData[2];
    const cost = excelData[3];
    const transformed = [];
    console.log(excelData)
    console.log(availableRooms)
    Object.keys(availableRooms).forEach(i => {
        if (availableRooms[i] !== 0 || bookedRooms[i] !== 0 || cost[i] !== 0) {
            const date = `${currentYear}-${currentMonth}-${String(i).padStart(2, '0')}`;
            console.log(availableRooms[i])
            transformed.push({
                id: `123-${hostelName}-${date}`,
                created_at: new Date().toISOString(),
                roomType: hostelName,
                hostelId: "123",
                date,
                availableRooms: availableRooms[i],
                bookedRooms: bookedRooms[i],
                cost: cost[i],
                isVisible: true
            });
        }
    })

    console.log (transformed)

    return transformed;
}