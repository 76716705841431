export const convertPayments = (allPayments) => {

    const result = []
    console.log(allPayments)
    allPayments.data.forEach(element => {
        result.push({
            id: element.id,
            paymentDate: element.created_at, 
            bedValueSold: element.totalRevenue,
            revenueCollected: element.paymentDone,
            collectionPeriod: element.t_start,
            notes: element.transaction_notes,
        });
    });
    console.log(result)
    return result
}

