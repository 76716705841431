import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { InputGroup, InputGroupText, Input, Button } from "reactstrap";
import axios from "axios";
import { baseUrl } from "..";

const column = [
    { field: 'bookingDate', headerName: 'Booking Date', flex: 1 },
    { field: 'booking_id', headerName: 'Booking Id', flex: 1 },
    { field: 'roomType', headerName: 'Room Type', flex: 1 },
    { field: 'customerName', headerName: 'Customer', flex: 1 },
    { field: 'checkInDate', headerName: 'CheckIn Date', flex: 1 },
    { field: 'checkOutDate', headerName: 'CheckOut Date', flex: 1 },
    { field: 'totalRooms', headerName: 'No. of Rooms', flex: 1 },
    { field: 'cost', headerName: 'Cost', flex: 1 },
    { field: 'id', headerName: 'Action', flex: 1 },
]

class Reservation extends React.Component {
    constructor() {
        super()
        this.state = {
            pageSize: 10,
            currentPage: 0,
            searchInput: "",
            allTableData: []
        }
    }

    componentDidMount() {
        const headers = {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            }
        }
        axios.get(`${baseUrl}/reservations/`, headers).then(response => {
            this.setState({ allTableData: response.data })
        }).catch(err => {
            console.log(err.message)
        })
    }

    render() {

        const onChange = (e) => {
            const { name, value } = e.target
            if (name === "pageSize") {
                this.setState({ currentPage: 0 })
            }
            this.setState({ [name]: value })
        }

        return (
            <div className="m-5">
                <div className="d-flex justify-content-between mb-3">
                    <InputGroup className="border rounded w-25">
                        <InputGroupText className="bg-transparent border-0">
                            <i class="bi bi-search"></i>
                        </InputGroupText>
                        <Input className="border-0" placeholder="Search..." onChange={onChange} value={this.state.searchInput} name="searchInput" />
                    </InputGroup>
                    {/* <div className="d-flex gap-3 align-items-center">
                        <Label>Rows per page</Label>
                        <Select
                            value={this.state.pageSize}
                            onChange={onChange}
                            name="pageSize"
                            label="Rows per page"
                        >
                            <MenuItem value={5}>5</MenuItem>
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={20}>20</MenuItem>
                        </Select>
                    </div> */}
                    <Button className="bg-secondary-o text-dark-o border-0" color="dark">
                        Download
                    </Button>
                </div>
                <DataGrid
                    rows={this.state.allTableData}
                    columns={column}
                    pageSize={this.state.pageSize}
                    rowsPerPageOptions={[1, 2, 4]}
                    pagination
                    autoHeight
                    onPageChange={(params) => this.setState({ currentPage: params.page })}
                    onPageSizeChange={(params) => this.setState({ pageSize: params.pageSize })}
                />
            </div>
        )
    }
}

export default Reservation